const dateTimeFormat = new Intl.DateTimeFormat("uk-UA", {
  timeZone: "Europe/Kyiv",
  day: "numeric",
  month: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hourCycle: "h23"
})

const dateFormat = new Intl.DateTimeFormat("uk-UA", {
  timeZone: "Europe/Kyiv",
  day: "numeric",
  month: "numeric",
  year: "numeric"
})

const timeFormat = new Intl.DateTimeFormat("uk-UA", {
  timeZone: "Europe/Kyiv",
  timeStyle: "short",
  hourCycle: "h23"
})

const timeWithSecondsFormat = new Intl.DateTimeFormat("uk-UA", {
  timeZone: "Europe/Kyiv",
  timeStyle: "medium",
  hourCycle: "h23"
})

export const getFormatedDateTime = (dateTimeString) => {
  return dateTimeString ? dateTimeFormat.format(new Date(dateTimeString)).replace(",", "") : ""
}

export const getFormatedDate = (dateTimeString) => {
  return dateTimeString ? dateFormat.format(new Date(dateTimeString)) : ""
}

export const getFormatedTime = (dateTimeString, withSeconds = false) => {
  if (withSeconds) {
    return dateTimeString ? timeWithSecondsFormat.format(new Date(dateTimeString)) : ""
  }

  return dateTimeString ? timeFormat.format(new Date(dateTimeString)) : ""
}

export const getFormatedDateTimeByTimestamp = (timestampString) => {
  return timestampString
    ? dateTimeFormat.format(new Date(timestampString * 1e3)).replace(",", "")
    : ""
}

export const getFormatedDateByTimestamp = (timestampString) => {
  return timestampString ? dateFormat.format(new Date(timestampString * 1e3)) : ""
}

export const getFormatedTimeByTimestamp = (timestampString, withSeconds = false) => {
  if (withSeconds) {
    return dateTimeString ? timeWithSecondsFormat.format(new Date(timestampString * 1e3)) : ""
  }

  return timestampString ? timeFormat.format(new Date(timestampString * 1e3)) : ""
}
