<template>
  <div class="offer-tags-list">
    <PageHeader :title="$t('tagsList')">
      <template #filters>
        <a-input-search
          v-for="field in searchValues"
          :key="`${field}`"
          :placeholder="$t(`tag.${field}`)"
          v-model="filteredInfo[field]"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button>
              <a-icon type="search" />
            </a-button>
          </template>
        </a-input-search>

        <a-form-item :help="$t('creatingDate')">
          <a-range-picker
            style="width: 100%"
            v-model="filteredInfo.created_at_interval"
            :showTime="{
              defaultValue: defaultTime
            }"
            allowClear
            valueFormat="YYYY-MM-DDTHH:mm:ss"
            @change="handleTableFilterChange()"
          />
        </a-form-item>

        <a-form-item :help="$t('editDate')">
          <a-range-picker
            style="width: 100%"
            v-model="filteredInfo.updated_at_interval"
            :showTime="{
              defaultValue: defaultTime
            }"
            allowClear
            valueFormat="YYYY-MM-DDTHH:mm:ss"
            @change="handleTableFilterChange()"
          />
        </a-form-item>

        <a-select
          v-model="filteredInfo.is_active"
          :options="isActiveOptions"
          :placeholder="$t('status')"
          allowClear
          style="margin-top: 2px"
          @change="handleTableFilterChange()"
        />
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset"
        >
          {{ $t("resetFilters") }}
        </a-button>

        <a-button
          type="primary"
          icon="plus"
          @click="handleOpenCreateModal()"
        >
          {{ $t("createTag") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="paginationInfo"
      :loading="isFetching"
      rowKey="uuid"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="text">
        <TableRendererDateTime :date="text" />
      </template>

      <template #quantity="{ offers_count, available_offers_count }">
        {{ available_offers_count }}/{{ offers_count }}
        {{ $t("pieces") }}
      </template>

      <template #clampedText="text">
        <div class="clamped-text">
          {{ text }}
        </div>
      </template>

      <template #is_active="text, { uuid }">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <a-badge :status="text ? 'success' : 'error'" />
          {{ text ? $t("active") : $t("inactive") }}
          <a-icon
            type="edit"
            @click="goToEditTag(uuid)"
          />
        </div>
      </template>
    </a-table>

    <a-drawer
      :title="$t('creatingTags')"
      :visible="modalVisible"
      :width="600"
      @close="handleOpenCreateModal()"
    >
      <CreateTag @close="handleOpenCreateModal()" />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import moment from "moment"
import router from "@/router"

import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/"
import CreateTag from "./_components/CreateTag.vue"
import PageHeader from "@/ant-components/PageHeader/index.js"

import { fetchTags } from "@/modules/MPAdmin/services/tagsService.js"

import useMPAConsts from "@/modules/MPAdmin/constants.js"
import useTagsPageColumns from "@/modules/MPAdmin/pages/Tags/useTagsPageColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const { isActiveOptions } = useMPAConsts()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchTags,
  requestParams: { changeRouterQuery: true }
})
const { columns } = useTagsPageColumns({ sortedInfo: computed(() => sortedInfo.value) })

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const modalVisible = ref(false)
const searchValues = ["title", "slug", "seo_h1", "_note"]

const defaultTime = computed(() => {
  return [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")]
})

const handleOpenCreateModal = () => {
  modalVisible.value = !modalVisible.value
}

const goToEditTag = (uuid) => {
  router.push({
    path: `/mp-admin/offer-tags/edit/${uuid}`
  })
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
