<template>
  <div>
    <PageHeader :title="$t('users')">
      <template #filters>
        <a-form-item>
          <a-input-search
            v-model="filteredInfo.username"
            :placeholder="$t('userName')"
            allowClear
            @search="handleTableFilterChange"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-item>
      </template>

      <template #actions>
        <a-button
          icon="undo"
          :loading="isFetching"
          @click="handleTableFiltersReset"
        >
          {{ $t("resetFilters") }}
        </a-button>
        <a-button
          type="primary"
          icon="plus"
          @click="openCreateModal()"
        >
          {{ $t("create") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      rowKey="id"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="paginationInfo"
      :loading="isFetching"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #contactsRenderer="email">
        <UserContacts :email="email" />
      </template>

      <template #isActiveRenderer="isActive">
        <div :class="[isActive ? 'success-text' : 'secondary-text']">
          {{ isActive ? $t("active") : $t("inactive") }}
        </div>
      </template>

      <template #actionsRenderer="record">
        <div class="table-row-actions">
          <a-button
            type="dashed"
            :disabled="isMetaFetching"
            @click="openRolesModal(record)"
          >
            {{ $t("roles") }}
          </a-button>
          <a-button
            type="dashed"
            shape="circle"
            icon="edit"
            @click="openEditModal(record)"
          />
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="rolesModalIsOpened"
      :width="600"
      :title="$t('roles')"
      @close="closeRolesModal"
    >
      <UpdateRoles
        v-if="rolesModalIsOpened"
        :userId="chosenUser?.id"
        :selectedRoles="chosenUser?.roles"
        :allRoles="roles"
        @onUpdate="onUpdateRoles"
      />
    </a-drawer>

    <a-drawer
      :visible="createModalIsOpened"
      :title="$t('createUser')"
      :width="600"
      destroyOnClose
      @close="closeCreateModal"
    >
      <CreateModal @onCreate="onCreateUser" />
    </a-drawer>

    <a-drawer
      :visible="editModalIsOpened"
      :title="$t('edit')"
      :width="600"
      @close="closeEditModal"
    >
      <EditModal
        v-if="editModalIsOpened"
        :username="chosenUser?.username"
        :email="chosenUser?.email"
        :id="chosenUser?.id"
        @onUpdateUser="onUpdateUser"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import UserContacts from "@/modules/MPAdmin/pages/renderers/UserContacts.vue"
import UpdateRoles from "./components/UpdateRoles.vue"
import CreateModal from "./components/CreateUserModal.vue"
import EditModal from "./components/EditUserModal.vue"

import PageHeader from "@/ant-components/PageHeader/index.js"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/index.js"

import { getRoles } from "@/modules/UsersManagement/services/rolesService.js"
import { getUsers } from "@/modules/UsersManagement/services/usersService.js"

import useUsersColumns from "../../useUsersColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
  // updateTableDataRecord
} = useAntTableQuery({
  queryFunction: getUsers,
  requestParams: { changeRouterQuery: true }
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}
const { usersColumns: columns } = useUsersColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const rolesModalIsOpened = ref(false)
const editModalIsOpened = ref(false)
const createModalIsOpened = ref(false)
const userRoles = ref([])
const chosenUser = ref()
const roles = ref([])
const isMetaFetching = ref(false)

const getMeta = async () => {
  try {
    isMetaFetching.value = true

    const { data } = await getRoles({ queryParams: { pageSize: 100 } })

    roles.value = data.results
    isMetaFetching.value = false
  } catch (error) {
    if (error?.response?.status === 401) return

    setTimeout(getMeta, 2500)
  }
}

const openRolesModal = (row) => {
  chosenUser.value = row
  userRoles.value = row.roles
  rolesModalIsOpened.value = true
}

const closeRolesModal = () => {
  chosenUser.value = undefined
  userRoles.value = []
  rolesModalIsOpened.value = false
}

const onUpdateRoles = () => {
  closeRolesModal()
  onTableChange()
}

const openCreateModal = () => {
  createModalIsOpened.value = true
}

const closeCreateModal = () => {
  createModalIsOpened.value = false
}

const openEditModal = (row) => {
  chosenUser.value = { ...row }
  editModalIsOpened.value = true
}

const closeEditModal = () => {
  editModalIsOpened.value = false
  chosenUser.value = undefined
}

const onUpdateUser = () => {
  // TODO: change table updating to row updating after API_V2 will be available
  // updateTableDataRecord({ payload, identifier: "id" })
  closeEditModal()
  onTableChange()
}

const onCreateUser = () => {
  closeCreateModal()
  onTableChange()
}

onMounted(() => {
  getMeta()

  setupTable({
    defaultSorter: {
      columnKey: "created_at",
      order: "ascend"
    }
  })
  onTableChange()
})
</script>

<style scoped lang="scss">
.table-row-actions {
  display: flex;
  gap: 8px;
}

.success-text {
  color: $green-color;
}
.secondary-text {
  opacity: 0.45;
}
</style>
