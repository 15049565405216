<template>
  <div>
    <PageHeader :title="$t('exportPageTitle')">
      <template #filters>
        <a-form-item>
          <a-input-search
            v-model="filteredInfo.code"
            placeholder="MPC-code"
            allowClear
            @search="handleTableFilterChange"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-item>

        <a-form-item>
          <a-input-search
            v-model="filteredInfo.p_code"
            placeholder="P-code"
            allowClear
            @search="handleTableFilterChange"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-item>

        <a-form-item>
          <a-input-search
            v-model="filteredInfo.meta_xml_seller_slug"
            :placeholder="$t('exportSellerSlug')"
            allowClear
            @search="handleTableFilterChange"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-item>

        <!-- <a-auto-complete
          v-model="filteredInfo.type"
          :placeholder="$t('type')"
          allowClear
          @search="onSearchType"
          @select="handleTableFilterChange"
          @blur="onTypeBlur"
          @change="onAutoCompleteChange"
        >
          <template #dataSource>
            <a-select-option
              v-for="item of fetchedTypes"
              :key="item.oid"
              :value="item.oid"
            >
              {{ item.full_name }}
            </a-select-option>
          </template>
        </a-auto-complete>

        <a-auto-complete
          v-model="filteredInfo.brand"
          :placeholder="$t('brand')"
          allowClear
          @search="onSearchBrand"
          @select="handleTableFilterChange"
          @blur="onBrandBlur"
          @change="onAutoCompleteChange"
        >
          <template #dataSource>
            <a-select-option
              v-for="item of fetchedBrands"
              :key="item.oid"
              :value="item.oid"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-auto-complete> -->

        <a-form-item :help="$t('creatingDate')">
          <a-range-picker
            v-model="filteredInfo.created_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            allowClear
            @change="handleTableFilterChange"
          />
        </a-form-item>

        <a-form-item>
          <a-select
            :placeholder="$t('exportProductStatus')"
            v-model="filteredInfo.status"
            mode="multiple"
            allowClear
            style="width: 100%"
            @change="handleTableFilterChange"
          >
            <a-select-option key="VERIFIED"> VERIFIED </a-select-option>
            <a-select-option key="TEMP"> TEMP </a-select-option>
            <a-select-option key="TRANSFERRED"> TRANSFERRED </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-input-search
            v-model="filteredInfo.public_title"
            :placeholder="$t('nameForPublish')"
            allowClear
            @search="handleTableFilterChange"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>
        </a-form-item>
      </template>

      <template #actions>
        <a-form-item>
          <a-button
            icon="undo"
            @click="handleTableFiltersReset"
          >
            {{ $t("clearFilters") }}
          </a-button>
        </a-form-item>

        <a-form-item>
          <a-popconfirm
            :title="$t('exportStartConfirm')"
            :okText="$t('yes')"
            :cancelText="$t('no')"
            placement="topRight"
            @confirm="handleExportConfirm"
          >
            <a-button
              type="primary"
              icon="play-circle"
              :disabled="!paginationInfo?.total"
              :loading="isExporting"
            >
              Export ({{ paginationInfo.total }})
            </a-button>
          </a-popconfirm>
        </a-form-item>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="code"
      @change="onTableChange"
    >
      <template #dateRenderer="text">
        <TableRendererDateTime :date="text" />
      </template>

      <template #metaRenderer="meta">
        <div
          v-if="meta"
          v-for="item in meta?.xml"
          :key="item.xml_id"
        >
          {{ item.seller_slug }}
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import PageHeader from "@/ant-components/PageHeader"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import useExportColumns from "./useExportColumns"
import { exportProducts, fetchProductsForExport } from "../services/exportService"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { fetchProductBrands, fetchProductTypes } from "../services/moderationProductsService"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchProductsForExport,
  requestParams: { changeRouterQuery: true }
})
const { columns } = useExportColumns()

const isExporting = ref(false)
const debouncedApiCall = ref(null)
const fetchedBrands = ref([])
const fetchedTypes = ref([])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const handleExportConfirm = async () => {
  try {
    isExporting.value = true

    await exportProducts({
      paginationInfo: paginationInfo.value,
      filteredInfo: filteredInfo.value,
      sortedInfo: sortedInfo.value
    })

    notification.success({ message: i18n.t("exportStartSuccess") })
  } catch (e) {
    notifyResponseError({ message: i18n.t("exportStartError") })
  } finally {
    isExporting.value = false
  }
}

const onSearchBrand = async (value) => {
  clearTimeout(debouncedApiCall.value)

  if (!value) {
    fetchedBrands.value = []
    return
  }

  debouncedApiCall.value = setTimeout(async () => {
    try {
      const { data } = await fetchProductBrands(value)
      fetchedBrands.value = data.results
    } catch (error) {
      notifyResponseError({ error })
    } finally {
      debouncedApiCall.value = null
    }
  }, 500)
}

const onSearchType = async (value) => {
  clearTimeout(debouncedApiCall.value)

  if (!value) {
    fetchedTypes.value = []
    return
  }

  debouncedApiCall.value = setTimeout(async () => {
    try {
      const { data } = await fetchProductTypes(value)
      fetchedTypes.value = data.results
    } catch (error) {
      notifyResponseError({ error })
    } finally {
      debouncedApiCall.value = null
    }
  }, 500)
}

const onBrandBlur = () => {
  if (
    !fetchedBrands.value.find(({ oid }) => oid === filteredInfo.value.brand) &&
    filteredInfo.value.brand
  ) {
    filteredInfo.value.brand = undefined
    handleTableFilterChange()
  }
}

const onTypeBlur = () => {
  if (
    !fetchedTypes.value.find(({ oid }) => oid === filteredInfo.value.type) &&
    filteredInfo.value.type
  ) {
    filteredInfo.value.type = undefined
    handleTableFilterChange()
  }
}

const onAutoCompleteChange = (value) => {
  if (value) return
  handleTableFilterChange()
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>
